// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navigation/navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navigation/navbar.tsx");
}
// REMIX HMR END

import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle } from "@nextui-org/react";
import "tailwindcss/tailwind.css?__remix_sideEffect__";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShield } from "@fortawesome/free-solid-svg-icons";
export function StickyNavbar({
  user
}) {
  _s();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuItems = {
    Home: "/",
    "Explore Map": "/map",
    "Field Journal": "/field-journal",
    "Project Origins": "/origins",
    Routes: "/routes",
    Quizzes: "/quizhome",
    "Plant of The Day": "/plantOfTheDay",
    "Leave Feedback": "https://forms.gle/v52MpNT3XJUmLMzg9",
    ...(user && user.userLevel > 0 ? {
      "Admin Dashboard": "/admin"
    } : {})
  };
  return <Navbar className="dark bg-black" onMenuOpenChange={setIsMenuOpen}>
      <NavbarContent justify="start">
      <Link color="foreground" href="/">
        <NavbarBrand>
          <img src="/assets/images/leaves.png" alt="Brand Logo" className="h-8 w-8 mr-2" />

          
          <div className="hidden xm:block font-bold">
            <span style={{
              color: "#e21833"
            }}>Roots</span>{" "}
            <span className="text-gray-400">and</span>{" "}
            <span style={{
              color: "#ffd200"
            }}>Routes</span>
          </div>
          <div className="block xm:hidden font-bold">
            <span style={{
              color: "#e21833"
            }}>R</span>
            <span className="text-gray-400">a</span>
            <span style={{
              color: "#ffd200"
            }}>R</span>
          </div>

        </NavbarBrand>
        </Link>
      </NavbarContent>

      <NavbarContent justify="center" className="">
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} className="xm:hidden text-white" />

      </NavbarContent>

      <NavbarContent className="hidden xm:flex gap-4" justify="center">
        {/* <NavbarItem>
           <Link color="foreground" href="/">
             Home
           </Link>
          </NavbarItem> */}
        <NavbarItem>
          <Link href="/map" aria-current="page" color="foreground">
            Explore Map
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="/routes">
            View Routes
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link color="foreground" href="/field-journal">
            Field Journal
          </Link>
        </NavbarItem>
         <NavbarItem>
        
          <Link color="foreground" href="/origins">
            Project Origins
          </Link>
        </NavbarItem> 

        <NavbarItem>
          <Link color="foreground" href="/quizhome">
            Quizzes
          </Link>
        </NavbarItem>
        <NavbarItem>
          <a style={{
          color: "white"
        }} href="https://forms.gle/v52MpNT3XJUmLMzg9" target="_blank">

            Leave Feedback
          </a>
        </NavbarItem>
        {user && user.userLevel > 0 && <NavbarItem>
            <Link color="danger" href="/admin">
              <FontAwesomeIcon icon={faShield} size="lg" />
            </Link>
          </NavbarItem>}

      </NavbarContent>
      <NavbarMenu className="bg-opacity-85 bg-black h-auto overflow-y-scroll">
        {Object.entries(menuItems).map(([key, value], index) => <NavbarMenuItem key={key}>
            <Link color={index === Object.entries(menuItems).length - 1 && user && user.userLevel > 0 ? "danger" : "secondary"} className="w-full text-xl p-2 bg-slate-900 rounded-md" href={value} size="lg">

              {key}
            </Link>
          </NavbarMenuItem>)}
      </NavbarMenu>

      <NavbarContent as="div" justify="end">
        {user ? <LoggedIn user={user} /> : <NotLoggedIn />}
      </NavbarContent>
    </Navbar>;
}
_s(StickyNavbar, "vK10R+uCyHfZ4DZVnxbYkMWJB8g=");
_c = StickyNavbar;
function LoggedIn({
  user
}) {
  return <Dropdown placement="bottom-end">
      <DropdownTrigger>
        <img src="/assets/images/avatar.png" alt="Profile Picture" className="h-8 w-8 rounded-full border-blue-400 border-1.5" />

      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem key="profile" className="h-12 gap-2" href="/profile">
          <p className="font-semibold">Signed in as {user.username}</p>
        </DropdownItem>
        <DropdownItem onClick={async () => {
        try {
          const response = await fetch("/logout", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            credentials: "include"
          });
          if (!response.ok) {
            throw new Error("Failed to log out");
          }
          if (response.redirected) {
            window.location.href = response.url;
          }
        } catch (error) {
          console.error("Logout error:", error.message);
        }
      }} key="logout" color="danger">

          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>;
}
_c2 = LoggedIn;
function NotLoggedIn() {
  return <Dropdown placement="left">
      <DropdownTrigger>
        <img src="/assets/images/avatar.png" alt="Profile Picture" className="h-8 w-8 rounded-full border-blue-400 border-1.5" />

      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownItem key="login" href="/login">
          Login
        </DropdownItem>
        <DropdownItem key="register" href="/register">
          Register
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>;
}
_c3 = NotLoggedIn;
var _c, _c2, _c3;
$RefreshReg$(_c, "StickyNavbar");
$RefreshReg$(_c2, "LoggedIn");
$RefreshReg$(_c3, "NotLoggedIn");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;